/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){

      $(window).on('scroll', function() {
        var currentScroll = document.scrollingElement.scrollTop;
        var pageHeight = $(window).height();

        console.log(currentScroll);
        console.log(pageHeight);

        if(!$('body').hasClass('home')) {
          if(currentScroll >= 10){
            $('#top-bar').addClass('fixed');
          }else {
            $('#top-bar').removeClass('fixed');
          }
        }        
      });

      //----- ACCORDEONS ------//
      $('.accordeon .accordeon-title').on('click', function() {
        $(this).parent().find('.accordeon-content').slideToggle();
        $(this).parent().toggleClass('open');
      });

      //------- VIDEO LIGHTBOX -------        

        //Anchor animations
      $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        if(the_id !== '#') {

          $('html, body').animate({
            scrollTop: $(the_id).offset().top-160
          }, 'slow');

        }
        return false;

      });

        //Open lightbox
        $('.lightboxTrigger').on('click', function() {
          $('#video-lightbox').removeClass('hidden-lightbox');
          
            var iframe = $('#video-lightbox iframe');

            var isYoutube = iframe.attr('src').indexOf('youtube');

            if(isYoutube !== -1){
              isYoutube = true;
            }else {
              isYoutube = false;
            }

            if(isYoutube == false){
              var player = new Vimeo.Player(iframe); 
            }            

            setTimeout(function() {
              $('#video-lightbox').addClass('open');
              $('body').addClass('no-scroll');
              
              if(isYoutube == false){
                player.play(); 
              }

            }, 750);    

            return false;
        });  

        //Close video lightbox, on click on close-button
        $('.close-videoPanel').click(function() {
            $('#video-lightbox').removeClass('open');

            if($('#video-lightbox iframe').length > 0){

              var iframe = $('#video-lightbox iframe');

              var isYoutube = iframe.attr('src').indexOf('youtube');

              if(isYoutube !== -1){
                isYoutube = true;
              }else {
                isYoutube = false;
              }

              if(isYoutube == false){

                var player = new Vimeo.Player(iframe);

                player.pause();

              }

            }

            setTimeout(function() {
                $('body').removeClass('no-scroll');
                $('#video-lightbox').addClass('hidden-lightbox');
            }, 600);

        });

        $('.events-list').slick({
          dots: true,
          infinite: false,
          slidesToShow: 3,
          autoplay: false,
          cssEase: 'ease-out',
          arrows: false,
          adaptiveHeight: true,
           responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });

        $('.fw-slider .slider').slick({
          dots: true,
          infinite: true,
          autoplay: true,
          speed: 400,
          cssEase: 'ease-out'
        });

        $('.lodge-slider').slick({
          dots: true,
          infinite: true,
          autoplay: false,
          speed: 400,
          cssEase: 'ease-out'
        });

        $('.slider-hook-1').slick({
          dots: true,
          infinite: true,
          autoplay: true,
          arrows: false,
          appendDots: $('.hook-slider-nav-1'),
          speed: 400,
          cssEase: 'ease-out'
        });

        $('.hook-slider-nav-1').each(function() {

          var totalSlide = $(this).find('li').length;

          $(this).find('li').each(function() {
            var currentIndex =  $(this).find('button').html();

            $(this).prepend('<span>'+currentIndex+'/'+totalSlide+'</span>');
          });

        });

        $('.slider-hook-2').slick({
          dots: true,
          infinite: true,
          autoplay: true,
          arrows: false,
          appendDots: $('.hook-slider-nav-2'),
          speed: 400,
          cssEase: 'ease-out'
        });

        $('.hook-slider-nav-2').each(function() {

          var totalSlide = $(this).find('li').length;

          $(this).find('li').each(function() {
            var currentIndex =  $(this).find('button').html();
            $(this).prepend('<span>'+currentIndex+'/'+totalSlide+'</span>');
          });

        });

        $('#home-subnav .subnav').slick({
          dots: true,
          slidesToShow: 3,
          infinite: true,
          autoplay: false,
          speed: 400,
          arrows: false,
          cssEase: 'ease-out',
          responsive: [
            {
            breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
            {
            breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        });

        $('#bt-menu').click(function() {
          $('.mobile-nav').toggleClass('menu-open');
          $(this).toggleClass('menu-open');
          $('body').toggleClass('no-scroll');
        });

      //Gallery
      //
      $('#gallery-lightbox').addClass('hidden-lightbox');
    $('#gallery-filters li a').on('mousedown touchstart click',function() {
      var list = $(this).attr('data-list');

      $('#gallery-filters li a.active').removeClass('active');
      $(this).addClass('active');
      
      if(list == 'photos') {
        $('#photos-list').slideDown();
        $('#videos-list').slideUp();
      }else {        
        $('#photos-list').slideUp();
        $('#videos-list').slideDown();
      }

    });

    //Close video lightbox, on click on close-button
    $('.close-videoPanel').click(function() {
        closeLightBox();
        $('#gallery-lightbox').removeClass('open');
        setTimeout(function() {
          $('body').removeClass('no-scroll');
          $('#gallery-lightbox').addClass('hidden-lightbox');
        }, 600);
    });
      

    $('.photo-video-album').on('click', function() {
       var data_id = $(this).attr('data-id');
       var album_type = $(this).parent().parent().parent().parent().attr('id');
       album_type = album_type.replace('-list','');

       openLightBox(data_id, album_type);
    });
      

    $('.homepage-content-image img').on('click', function() {
       openLightBoxHome();
    });


      $('body').addClass('loaded');
  }

  function openLightBox(album_id, type) {    

    $('#gallery-lightbox').removeClass('hidden-lightbox');
    setTimeout(function() {
      $('#gallery-lightbox').addClass('open');
    }, 500);     

    $('body').addClass('no-scroll');

    $('.gallery[gallery-id="'+album_id+'"]').fadeIn();

    if(!$('.gallery[gallery-id="'+album_id+'"]').hasClass('slider-actif')) {
      
      $('.gallery[gallery-id="'+album_id+'"]').addClass('slider-actif');

      $('.gallery[gallery-id="'+album_id+'"] .slider-gallery').slick({
        infinite: false,
        slidesToShow: 1,
        arrows: true,
        autoplay: false
      });
    }   

    if(type == 'videos') {
        var iframe = $('.gallery[gallery-id="'+album_id+'"] iframe');
        var player = new Vimeo.Player(iframe);
        player.play();
    }

  }

  function closeLightBox(){
    $('#gallery-lightbox .gallery').fadeOut();
    if($('#gallery-lightbox .gallery.slider-actif iframe').length > 1){
      var iframe = $('#gallery-lightbox .gallery.slider-actif iframe');        
      var player = new Vimeo.Player(iframe);        
      player.pause(); 
    }              
    
    //$('.gallery.slider-active .slider-gallery').slick('unslick');
  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
